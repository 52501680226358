import React, {useEffect, useState} from "react";
import "./style.css";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {BASEURL} from "../../../API/BaseUrl";
import toast from "react-hot-toast";
import UseGeneral from "../../../hooks/useGeneral";

const Categories = () => {
  

  
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [loadig, setLoading] = useState(false);

  const selectCategories = async () => {
    setLoading(true);

    await axios
      .get(`${BASEURL}products/select_categories.php`)
      .then((res) => {
        if (res.data.status == "success") {
          setData(res?.data?.message);
          setOriginalData(res?.data?.message);
        } else {
         // toast.error("هناك مشكلة ما");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    selectCategories();
  }, []);

  const navigate = useNavigate();
  const {language} = UseGeneral();
  const {id} = useParams();

  return (
    <div className='categories_container'>
      <div className='categories_container_title'>
        {language == "ar" ? "التصنيفات" : "Categories"}
      </div>
      <div className='first_four'>
        {data && data.length >= 1 && Array.isArray(data) ? (
          data?.slice(0, 8)?.map((item, index) => {
            return (
              <div
                key={index}
                className={`category_card ${
                  [1, 2, 5, 6].find((item) => item == index) ? "big" : "small"
                }`}
                style={{
                  gridColumn: [1, 5].find((item) => item == index)
                    ? "2/6"
                    : [2, 6].find((item) => item == index)
                    ? "1/5"
                    : "",
                  backgroundImage: [1, 2, 5, 6].find((item) => item == index)
                    ? `url(${item.image})`
                    : "",
                }}
                onClick={() => navigate(`/cats_prods/${item?.category_id}`)}
              >
                <div className='cat_card_text text-center'>
                  {language == "ar" ? item.name_ar : item.name_en}
                </div>
                {![1, 2, 5, 6].find((item) => item == index) ? (
                  <div className='cat_card_image'>
                    <img src={item.image} alt='' />
                  </div>
                ) : (
                  ""
                )}

                {/* {[1, 2, 5, 6].find((item) => item == index) ? (
                  <div className='card_overlay'></div>
                ) : (
                  ""
                )} */}
              </div>
            );
          })
        ) : (
          <div className='d-flex align-items-center justify-content-center w-100'>
            <div class='spinner-border text-primary text-center' role='status'>
              <span class='visually-hidden text-center'>Loading...</span>
            </div>
          </div>
        )}
      </div>
      <div className='after_first_four'>
        {data && data.length >= 1 && Array.isArray(data)
          ? data?.slice(8)?.map((item, index) => {
              return (
                <div
                  onClick={() => navigate(`/cats_prods/${item?.category_id}`)}
                  className={`category_card small`}
                >
                  <div className='cat_card_text'>
                    {language == "ar" ? item.name_ar : item.name_en}
                  </div>
                  <div className='cat_card_image'>
                    <img src={item.image} alt='' />
                  </div>
                </div>
              );
            })
          : ""}
      </div>
    </div>
  );
};

export default Categories;
